import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Hero from './Hero';

export default function Team() {
  return (
    <>
    <Hero />
      <Box
      id="hero2"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '85%' } }}>
        <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 2rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            Gary Cornell
          </Typography>
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(1.5rem, 10vw, 1.5rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            Founder
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1rem, 10vw, 1rem)', mt:2 }}
             variant='body1'>
            I got my Ph.D. in mathematics from Brown University. I started out as a pure mathematician in the “Queen of Mathematics” – number theory and that lasted for about 20 years. But then I left academia for good. Once I stopped teaching, I was, among other things, a visiting scientist at IBM’s Watson Labs and a program director at the United State’s National Science Foundation. I also wrote or co-wrote many books about programming and also co-edited two of the standard references in arithmetic geometry.  I’m most proud of the fact that I was the co-founder and CEO of Apress (www.apress.com) which I made the fastest growing publisher for IT professionals in the world during the period 2000-2007. But then I honored my pledge to step down when Apress got big enough to need an HR department when we were acquired by the company that is now Springer Nature. I worked with them as a “senior strategic advisor” for a while after my “liquidity event”, while doing some angel investing on the side.  I now occasionally  write articles on applying mathematics to the real world for non math people in places such as Slate and my blog but am so happy to be involved in a startup again, especially one like Scitility which hopes to improve scientific integrity and communication.
          </Typography>
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 2rem)',
                mt:2,
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            Jan-Erik de Boer
          </Typography>
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(1.5rem, 10vw, 1.5rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            Founder
          </Typography>
          <Typography
           textAlign="left"
           color="text.secondary"
           sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1rem, 10vw, 1rem)', mt:2 }}
            variant='body1'>
            Most recently I was  the CIO for Springer Nature, one of the largest STM (scientific, technical, medical) publishers in the world. The 24 years I was there gave me an extensive knowledge of the areas where technology impacts Academic Publishing. After roles as a manager and then later as chief information officer lasting more than two decades, I’ve realized that building products is what I want to do - but I want to do it  without all the politics that come with a big organization. For example, more than 12 years ago I was involved in building springerlink - the online platform that was widely regarded as the best in the industry. While doing that I had the pleasure of being part of a high performance team where we could focus on what was needed. Repeating that in a small company with people that are focussed and hands on, is what I want to do in  the future. I and the other founders of Scitility are working together to create products that will help the academic publishing industry to both improve their processes and reinstate the value that peer review has for science. 
(And when not building great products I tinker with home automation, which my wife does not always appreciate, on the raspberry pi, read and hike with our dog, either in the Netherlands or in Sweden.)
          </Typography>
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 2rem)',
                mt:2,
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            Antonio José Molina Gutiérrez
          </Typography>
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(1.5rem, 10vw, 1.5rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            Founder
          </Typography>
          <Typography
           textAlign="left"
           color="text.secondary"
           sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1rem, 10vw, 1rem)', mt:2 }}
            variant='body1'>
            Born in Spain, my passion for Science started at a very young age when I stumbled into the wonderful world of Paleontology and decided to learn to code to build a digital community way back in 1999. Professionally, I have spent more than 20 years working as an independent Software Engineer, leading multiple high performance teams in a variety of industries from Finance to Scientific Publishing. I was the Lead Engineering Consultant in charge of the rebuild of the Peer Review system for Springer Nature that was successfully rolled out to hundreds of journals. I have held Senior engineering positions for LMAX, helping deliver e-trading systems that manage over 1 trillion dollars in transactions per year, my code runs on the Point of Sale systems of the largest UK supermarket and I have helped numerous clients via consulting. Outside work I love dogs, videography and the big outdoors. 
          </Typography>
        </Stack>
      </Container>
    </Box>
    </>
  );
}
