import { alpha, Box, Container, Stack, Typography } from "@mui/material"

export default function NewPipelines() {
    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
                    <Typography
                        component="span"
                        variant="h2"
                        sx={{
                            fontSize: 'clamp(1.4rem, 10vw, 1.4rem)',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.dark' : 'primary.contrastText',
                        }}
                    >
                        27th March 2025
                    </Typography>
                    <Typography component="span"
                        variant="h1"
                        sx={{
                            fontSize: '2rem',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}>
                        New data pipelines to improve timeliness and transparency on the latest retractions
                    </Typography>                    
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        At Scitility, we believe technology and innovation should drive meaningful change. We are committed to improving research integrity by ensuring transparency and accountability. That's why we built Argos to help institutions, funders, and publishers combat research misconduct while making critical retraction data accessible rather than hidden and the cascading effects explainable rather than, well, trusting a “black box”.  
                    </Typography>
                    <Typography variant="h2"
                        sx={{
                            mt: 2,
                            mb: 0,
                            fontSize: '1.5rem',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}>
                        Just the facts of argos
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{
                            alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)'
                        }}>
                        We started with dashboards that provided key insights by publisher, and over time, we've expanded our capabilities. However, we identified a critical gap: the need for <strong>more real-time and accurate retraction tracking</strong>. To bridge this gap, we've worked to <strong>engineer new data pipelines</strong>, creating our own proprietary database, ensuring that Argos now provides <strong>the most up-to-date retraction information available</strong>.
                    </Typography>
                    <Typography variant="h2"
                        sx={{
                            mt: 2,
                            mb: 0,
                            fontSize: '1.5rem',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}>
                        A More Comprehensive View of Retractions
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{
                            alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)'
                        }}>
                        With the new release Argos now offers the <strong>most complete and accurate</strong> view of retractions, for example we now showcase <strong>17%</strong> more retractions in total. Even more significant: we've identified <strong>167% more retractions in the past 12 months</strong> than other sources, demonstrating our ability to uncover more cases and offer a clearer, more detailed picture of the evolving research landscape.
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        Our expanded dataset not only enhances our <strong>analytics capabilities</strong>, but we're also making this data more accessible through our <strong>new Retractions Dashboard</strong>, featuring the latest retractions and trends.
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        Even more importantly, this information is <strong>fed directly into the Scitility Risk Score</strong>. By updating the <strong>Scitility Risk Score daily</strong> with the latest retraction insights, we provide institutions, publishers, and journal editors with a <strong>leading indicator</strong>, helping them identify <strong>high-risk articles early</strong>, both before and after publication. Advanced users will get access to the DOI's for all retracted articles and we list the high risk articles by institution and journal.
                    </Typography>                
                    <Typography variant="h2"
                        sx={{
                            mt: 2,
                            mb: 0,
                            fontSize: '1.5rem',
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                        }}>
                        Time matters
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        The average time from publication to retraction is lengthy. The reporting of it and taking action on the retraction cascade shouldn't be.
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        <ul>
                            <li><strong>For Institutions: </strong>Detect problematic research trends, identify affiliations with high-risk collaborators, and strengthen oversight of published work across publishers. </li>
                            <li><strong>For Journal Editors: </strong>Gain deeper visibility into potential risks within your publication portfolio, and identify problematic articles in your pipeline.</li>
                            <li><strong>For Publishers: </strong>Identify and assess high-risk articles, before they become even larger liabilities.  Stop problematic papers before they are published.</li>
                        </ul>
                    </Typography>
                    <Typography textAlign="left"
                        color="text.secondary"
                        sx={{ alignSelf: 'left', width: { sm: '100%' }, fontSize: 'clamp(1.5rem, 10vw, 1.5rem)' }}>
                        By increasing transparency, providing explainability, and providing the most current insights through our <strong>industry leading data pipelines</strong>, Argos is helping the academic community take meaningful steps toward safeguarding research credibility.
                    </Typography>                    
                </Stack>
            </Container></Box>
    )
}