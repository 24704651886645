import * as React from 'react';
import { Container, Link, PaletteMode, Paper, Stack, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, alpha, createTheme } from '@mui/material/styles';
import getLPTheme from './getLPTheme';
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer';
import Team from './components/Team';
import Hero from './components/Hero';
import Sept18PressRelease from './blog/18_Sept_Press_Release';
import RiskyBusinessPage from './RiskyBusinessPage';


export default function BlogPage() {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };


  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box
        id="hero"
        sx={(theme) => ({
          width: '100%',
          backgroundImage:
            theme.palette.mode === 'light'
              ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
              : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
          backgroundSize: '100% 20%',
          backgroundRepeat: 'no-repeat',
        })}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
            <Container>
              <Typography component="span"
                variant="h1"
                sx={{
                  fontSize: 'clamp(2rem, 10vw, 2rem)',
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                }}>
                <Link href="/blog/new-pipelines" underline="none">New data pipelines to improve timeliness and transparency on the latest retractions</Link>
              </Typography>
              <Typography
                textAlign="left"
                color="text.secondary"
                sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1rem, 10vw, 1rem)', mt: 2 }}
                variant='body1'>
                New Data pipelines allow Scitility to have the most complete and accurate view on retractions. +167% more reported in the last 12 months!
                &nbsp;<Link href="/blog/new-pipelines" underline="none">See more...</Link>
              </Typography>
              <Typography variant='caption'>
                Mar 27, 2025
              </Typography>
            </Container>
            <Divider sx={{ m: 2 }} />
            <Container>
              <Typography component="span"
                variant="h1"
                sx={{
                  fontSize: 'clamp(2rem, 10vw, 2rem)',
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                }}>
                <Link href="/blog/risky-business" underline="none">Risky Business!</Link>
              </Typography>
              <Typography
                textAlign="left"
                color="text.secondary"
                sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1rem, 10vw, 1rem)', mt: 2 }}
                variant='body1'>
                argos analyzes information from several different sources and then calculates daily a risk score for all articles published since Jan 1st 2014. We take into account the reason for the retraction, its citations, and the history of authors. From this data and more we create a DAG (Directed acyclic graph) which allows us to calculate “risk” based on the observable and measurable level of impact from previous problematic retractions. This allows us to help you find those needles in the haystack!
                &nbsp;<Link href="/blog/risky-business" underline="none">See more...</Link>
              </Typography>
              <Typography variant='caption'>
                Sept 24, 2024
              </Typography>
            </Container>
            <Divider sx={{ m: 2 }} />
            <Container>
              <Typography component="span"
                variant="h1"
                sx={{
                  fontSize: 'clamp(2rem, 10vw, 2rem)',
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                }}>
                <Link href="/blog/press-release-sept-2024" underline="none">
                  Scitility PBC, a public benefit corporation, unveils argos, the revolutionary tool that makes visible the hidden patterns and cascading effects of retractions on scientific research.
                </Link>
              </Typography>
              <Typography
                textAlign="left"
                color="text.secondary"
                sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1rem, 10vw, 1rem)', mt: 2 }}
                variant='body1'>
                Scitility PBC (www.scitility.com), a technology first company whose mission is to improve scientific integrity, announced today the launch of its debut product: argos. argos, a groundbreaking free-to-individual solution, that tackles the growing challenge of scientific malpractice.
                &nbsp;<Link href="/blog/press-release-sept-2024" underline="none">See more...</Link>
              </Typography>
              <Typography variant='caption'>
                Sept 19, 2024
              </Typography>
            </Container>
          </Stack>
        </Container>
      </Box>
      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider sx={{ pb: 6 }} />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}