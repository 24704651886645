import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import cover from "./hero-background.jpg";
import Grid from '@mui/material/Grid';

export default function Hero() {
  return (
    <section className='hero'>

<Container id="header" sx={{ py: { xs: 8, sm: 8 }, mt: 10 }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <div>
            <Stack>  
          <Typography
            component="span"
            variant="h1"
            sx={{
              fontSize: 'clamp(1rem, 8vw, 4rem)',
              color: '#FFFFFF'}}
          >
            Research Integrity
          </Typography>
          <Typography
            component="span"
            variant="h1"
            sx={{
              fontSize: 'clamp(1rem, 8vw, 4rem)',
              color: '#FFFFFF'}}
          >
            Made Easy
          </Typography>
          <Typography
            textAlign="left"
            color="#d9d9d9"
            sx={{ alignSelf: 'left', width: { sm: '80%', md: '80%' }, fontSize: 'clamp(1rem, 8vw, 2rem)' }}
          >
            Cutting-edge products, delivering integrity solutions for Scientific Publishing.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 20, width: { xs: '100%', sm: 'auto' } }}
          >
          </Stack>
        </Stack>
        </div>
      </Grid>
      </Grid>
      </Container>
    </section>
  );
}
