import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Hero from './Hero';

export default function BlogPage() {
  return (
    <>
    <Hero />
    <Box
      id="hero"
       sx={(theme) => ({
        mt:6})}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '85%' } }}>
        <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 2rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            <Link href="/blog/risky-business" underline="none">
                 <b>Risky Business!</b>
                </Link>
          </Typography>
         
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1rem, 10vw, 1rem)' }}
          >
            argos analyzes information from several different sources and then calculates daily a risk score for all articles published since Jan 1st 2014. We take into account the reason for the retraction, its citations, and the history of authors. From this data and more we create a DAG (Directed acyclic graph) which allows us to calculate “risk” based on the observable and measurable level of impact from previous problematic retractions. This allows us to help you find those needles in the haystack!
                &nbsp;<Link href="/blog/risky-business" underline="none">See more...</Link>
              </Typography>
              <Typography variant='caption'>
                Sept 24, 2024
              </Typography> 
          <Typography
              component="span"
              variant="h2"
              sx={{
                fontSize: 'clamp(2rem, 10vw, 2rem)',
                mt: 2,
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
           <Link href="/blog/press-release-sept-2024" underline="none">
                 <b>Scitility PBC, a public benefit corporation, unveils argos, the revolutionary tool that makes visible the hidden patterns and cascading effects of retractions on scientific research.</b>
                </Link>
          </Typography>          
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1rem, 10vw, 1rem)' }}
          >
           Scitility PBC (www.scitility.com), a technology first company whose mission is to improve scientific integrity, announced today the launch of its debut product: argos. argos, a groundbreaking free-to-individual solution, that tackles the growing challenge of scientific malpractice.
                &nbsp;<Link href="/blog/press-release-sept-2024" underline="none">See more...</Link>
              </Typography>
              <Typography variant='caption'>
                Sept 19, 2024
              </Typography>
        </Stack>
      </Container>
    </Box>
    </>
  );
}
