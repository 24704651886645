import './App.css';
import LandingPage from './LandingPage';
import { Route, Routes } from 'react-router';
import TeamPage from './Team';
import BlogPage from './Blog';
import Sept18PressReleasePage from './18SeptPressReleasePage';
import RiskyBusinessPage from './RiskyBusinessPage';
import AboutUsPage from './AboutUsPage';

function App() {
  return (
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/team' element={<TeamPage />} />
      <Route path='/aboutus' element={<AboutUsPage />} />
      <Route path='/blog' element={<BlogPage />} />
      <Route path='/blog/press-release-sept-2024' element={<Sept18PressReleasePage />} />
      <Route path='/blog/risky-business' element={<RiskyBusinessPage />} />
    </Routes>
  );
}

export default App;
